import { $win } from '../utils/globals';

$win.on('load scroll', function() {
	var winT = $win.scrollTop();
	var winH = $win.height();

	if($('.js-parallax').length) {

		var parallaxPos = Math.min(winT/3, winH);

		$('.js-parallax').find(' > img').css({
			'transform': 'translateY(' + parallaxPos + 'px)',
			'-webkit-transform': 'translateY(' + parallaxPos + 'px)',
			'-moz-transform': 'translateY(' + parallaxPos + 'px)'
		});
	};
});
