import { $win } from "../utils/globals";

const barHeight = $(".bar").outerHeight();

$win.on("scroll", function () {
	const winScroll = $win.scrollTop();

	if (winScroll >= 10) {
		$(".header").css("top", -barHeight);
		$(".header").addClass("has-bg");
	} else {
		$(".header").css("top", 0);
		$(".header").removeClass("has-bg");
	}
});

$(window).on("load", function () {
	const winScroll = $win.scrollTop();
	if (winScroll >= 10) {
		$(".header").css("top", -barHeight);
		$(".header").addClass("has-bg");
	}

	let scrollTop = document.documentElement.scrollTop;

	console.log("scrollTop", scrollTop);

	// Get the offset (left, top) of #abs element after page load
	let { left, top } = $(".header").offset();

	console.log(".header top", top);

	if (scrollTop === 0) {
		// We are at the top
		$(".header").css("top", 0);
		$(".header").removeClass("has-bg");
	} else {
		// The page is scrolled down by scrollTop pixels
		$(".header").css("top", -barHeight);
		$(".header").addClass("has-bg");
		// Use scrollTop and left to calc new scroll value or set it to 0
		// You can use this to scroll the page at the top after each load
		// setTimeout(() => {
		//   window.scrollTo(0, 0);
		// }, 50);
	}
});
