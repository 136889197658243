const $button = $('.js-btn-filter');
const $buttonClose = $('.js-btn-close-filter');

$button.on('click', function (e) {
	e.preventDefault();
	$('body').addClass('has-filter');
});

$buttonClose.on('click', function (e) {
	e.preventDefault();
	$('body').removeClass('has-filter');
});
